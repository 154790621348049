import React from "react";
import ProductTemplate from "./product";
import NewProductTemplate from "../../components/sections/product-more-info/Item";

const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    const newProductsEnabled = process.env.GATSBY_NEW_PRODUCTS_ENABLED === "true";

    const Data = () => {
        return (
            <div className="space-y-5">
                <p>
                    <strong>NOU PROJECTE iOpos</strong>
                </p>

                <p>
                    <strong>Grup reduït màxim 10 alumnes</strong>
                </p>

                <p>
                    Neix un nou concepte a iOpos de la necessitat de tenir un{" "}
                    <strong>TUTOR exclusiu</strong> i tota la formació al teu abast.
                </p>

                <p>
                    Pensat exclusivament per persones que desitgin implicar-se al 100% i aconseguir
                    l’objectiu de convertir-se en Mosso/a en temps rècord.
                </p>

                <p>T’imagines tenir accés absolutament a tot el contingut de iOpos?</p>

                <ul>
                    <li>Llibres</li>
                    <li>Mòduls de l’aplicació</li>
                    <li>Cursos presencials de temari</li>
                    <li>Psicotècnics</li>
                    <li>Físiques</li>
                    <li>Preparacions personals d’entrevista</li>
                </ul>

                <p>
                    <strong>Sí, sí, absolutament tot!</strong> I a més, fer-ho rodejat de 9 companys
                    i companyes i d’un tutor de referència que ja és policia i et guiarà tot el
                    camí?
                </p>

                <p>
                    <strong>Doncs ara és possible!</strong>
                </p>

                <p>
                    Crearem un grup de treball que es convertirà en una pinya, des d’ara i fins al
                    final del procés de la convocatòria.
                </p>

                <p>
                    Començarem de 0, anirem pas a pas de manera coordinada amb totes les formacions
                    de iOpos, però a més:
                </p>

                <ul>
                    <li>Farem treballs a part</li>
                    <li>Quedarem i repassarem conceptes</li>
                    <li>Seguiments presencials i en línia setmanals</li>
                    <li>
                        Prepararem de forma concreta cada part de l'oposició quan arribi el moment
                    </li>
                </ul>

                <p>
                    <strong>
                        I tot això per 1899 €, amb formes de pagament que s’adapten al que
                        necessitis.
                    </strong>
                </p>

                <p>
                    Oblida de pagar cada curs, cada mòdul, cada sessió…{" "}
                    <strong>amb un únic pagament HO TENS TOT</strong>, amb l’acompanyament d’un
                    professional.
                </p>

                <p>
                    Però no podem esperar que arribin les proves, hem de començar a treballar ara
                    mateix, així que si estàs interessat o interessada a formar part d’aquest grup,{" "}
                    <strong>omple el formulari</strong> i ens posarem en contacte per explicar-te
                    més detalls i resoldre els dubtes que tinguis.
                </p>

                <p>
                    Aquest pla estarà disponible des del moment de la matriculació fins al final de
                    l'oposició de Mossos d'Esquadra 2025.
                </p>
            </div>
        );
    };

    return (
        <>
            {!newProductsEnabled ? (
                <ProductTemplate item={item}>
                    <Data />
                </ProductTemplate>
            ) : (
                <NewProductTemplate
                    item={item}
                    title="🔥 El camí més ràpid per ser Mosso/a! 🔥 Formació total, tutor exclusiu i un grup reduït amb el mateix objectiu: aprovar en temps rècord. 🚀💪 Places limitades! Reserva la teva ara."
                >
                    <Data />
                </NewProductTemplate>
            )}
        </>
    );
};

export default Product;
